const styles = {
  global: {
    body: {
      fontSize: "1rem",
      color: "gray.800",
      bg: "none",
    },
    "*, *::before, &::after": {
      wordWrap: null,
    },
  },
};

export default styles;
