import { useOrdinalColorScale } from "@nivo/colors";
import { identity } from "lodash";
import { useRecoilValue } from "recoil";
import { nivoColors } from "../constants";
import { amountState } from "../state";
import PieSection from "./PieSection";

export interface PieSectionContainerProps {
  pieData: Array<{
    id: string;
    label: string;
    value: number;
  }>;
}

const PieSectionContainer: React.FC<PieSectionContainerProps> = ({
  pieData,
}) => {
  // Get color scale for custom legend
  const getInheritorColor = useOrdinalColorScale<string>(nivoColors, identity);

  // Get optional amount from state
  const amount = useRecoilValue(amountState);

  return (
    <PieSection
      amount={amount}
      pieData={pieData}
      getInheritorColor={getInheritorColor}
    />
  );
};

export default PieSectionContainer;
