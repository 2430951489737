
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Text } from "@chakra-ui/react";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text textStyle="paragraph-sm" mdxType="Text">
  Se volete favorire una o più persone di fiducia o istituzioni menzionate, ciò
  deve essere stabilito sotto forma di disposizione testamentaria a causa di
  morte (testamento o contratto successorio).
    </Text>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;