import { snakeCase } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { amountState, optionalInheritorsState, sharesState } from "../state";
import Results from "./Results";

export interface ResultsContainerProps {}

const ResultsContainer: React.FC<ResultsContainerProps> = () => {
  const { t } = useTranslation();

  // Get calculated shares from state
  const shares = useRecoilValue(sharesState);

  // Get optional amount from state
  const amount = useRecoilValue(amountState);

  // Get optional inheritors from state
  const rawOptionalInheritors = useRecoilValue(optionalInheritorsState);
  // Translate them
  const optionalInheritors = Array.from(
    rawOptionalInheritors
  ).map((inheritor) => t(snakeCase(inheritor)));

  // Calculate total disposable portion by summing up individual portions
  const totalDisposablePortion = Array.from(shares).reduce(
    (sum, [_inheritor, share]) => sum + share.disposablePortion,
    0
  );

  // Set up pie data
  // Shares pie: Shares per eligible inheritor
  const sharesPieData = Array.from(shares).map(([inheritor, share]) => ({
    id: inheritor,
    label: t(snakeCase(inheritor)),
    value: share.share,
  }));

  // Portions pie: Statutory portions per inheritor + disposable portion
  const portionsPieData = Array.from(shares)
    .filter(([_inheritor, share]) => share.statutoryPortion > 0)
    .map(([inheritor, share]) => ({
      id: inheritor as string,
      label: t(snakeCase(inheritor)),
      value: share.statutoryPortion,
    }))
    .concat([
      {
        id: "disposable",
        label: t("disposable_portion"),
        value: totalDisposablePortion,
      },
    ]);

  return (
    <Results
      amount={amount}
      totalDisposablePortion={totalDisposablePortion}
      sharesPieData={sharesPieData}
      portionsPieData={portionsPieData}
      optionalInheritors={optionalInheritors}
    />
  );
};

export default ResultsContainer;
