import React from "react";
import { useRecoilState } from "recoil";
import { Inheritor } from "../models/inheritance";
import { selectedInheritorsState } from "../state";
import InheritorsWizard from "./InheritorsWizard";
import InheritorsWizardCircle from "./InheritorsWizardCircle";
import TwoCol from "./TwoCol";

export interface InheritorsWizardContainerProps {}

const InheritorsWizardContainer: React.FC<InheritorsWizardContainerProps> = () => {
  // Get selected inheritors from state
  const [inheritors, setInheritors] = useRecoilState(selectedInheritorsState);

  // Toggle inheritor function (used as arc click handler)
  const toggleInheritor = (inheritor: Inheritor) => {
    // Prepare new inheritors set, adding or deleting the changed value
    const newInheritors = new Set(inheritors);
    if (newInheritors.has(inheritor)) {
      newInheritors.delete(inheritor);
    } else {
      newInheritors.add(inheritor);
    }

    // Solo selected or set empty -> only solo
    if (
      newInheritors.size === 0 ||
      (newInheritors.has(Inheritor.solo) && inheritor === Inheritor.solo)
    ) {
      setInheritors(new Set([Inheritor.solo]));
      return;
    }

    // Anything but solo selected -> remove solo
    if (newInheritors.size > 1 && newInheritors.has(Inheritor.solo)) {
      newInheritors.delete(Inheritor.solo);
    }

    // Set new inheritors set to state
    setInheritors(newInheritors);
  };

  // Checkbox change handler
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Get checkbox value aka inheritor
    const inheritor = event.target.value as Inheritor;

    toggleInheritor(inheritor);
  };

  // Create selectable inheritors
  const selectableInheritors = [
    Inheritor.partner,
    Inheritor.mother,
    Inheritor.father,
    Inheritor.children,
    Inheritor.grandChildren,
    Inheritor.siblings,
    Inheritor.nieces,
    Inheritor.auntUncle,
    Inheritor.grandParents,
    Inheritor.cousins,
    Inheritor.solo,
  ];

  return (
    <TwoCol
      col1={
        <InheritorsWizard
          checkedInheritors={inheritors}
          checkboxValues={selectableInheritors}
          handleCheckboxChange={handleCheckboxChange}
          stepIdx={1}
        />
      }
      col2={
        <InheritorsWizardCircle
          checkedInheritors={inheritors}
          handleArcClick={toggleInheritor}
        />
      }
      col2Props={{
        alignSelf: "center",
      }}
    />
  );
};

export default InheritorsWizardContainer;
