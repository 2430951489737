import {
  Box,
  Divider,
  Icon,
  IconButton,
  Text,
  Tooltip
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FiAlertTriangle, FiPrinter } from "react-icons/fi";
import { SetterOrUpdater } from "recoil";
import { Step } from "../models/steps";
import AmountWizardContainer from "./AmountWizardContainer";
import ContactContainer from "./ContactContainer";
import InheritorsWizardContainer from "./InheritorsWizardContainer";
import OptionalInheritorsWizardContainer from "./OptionalInheritorsWizardContainer";
import ResultsContainer from "./ResultsContainer";
import StepperActions from "./StepperActions";
import StepperNavigation from "./StepperNavigation";
import TopActions from "./TopActions";
import TwoCol from "./TwoCol";

export interface StepperProps {
  steps: Step[];
  activeStep: Step;
  setStep: SetterOrUpdater<Step>;
}

const Stepper: React.FC<StepperProps> = ({ steps, activeStep, setStep }) => {
  const { t } = useTranslation();

  return (
    <Box as="main" position="relative">
      {/* Top actions */}
      {activeStep === 3 && (
        <TopActions>
          <Tooltip hasArrow label={t("print")} bg="blue.800">
            <IconButton
              color="gray.500"
              icon={<Icon as={FiPrinter} width={"60%"} height={"60%"} />}
              aria-label={t("print")}
              variant="ghost"
              borderRadius="none"
              onClick={(e) => {
                window.print();
                e.currentTarget.blur();
              }}
            />
          </Tooltip>
        </TopActions>
      )}

      {/* Stepper navigation */}
      <StepperNavigation
        steps={steps}
        activeStep={activeStep}
        setStep={setStep}
      />

      {/* Content */}
      <Box as="article" mt={{ base: 8, lg: 10 }}>
        {/* Step 1 content */}
        {activeStep === 1 && (
          <section>
            <InheritorsWizardContainer />

            {/* Stepper actions */}
            <Box mt={6}>
              <StepperActions nextStep={2} setStep={setStep} />
            </Box>
          </section>
        )}

        {/* Step 2 content */}
        {activeStep === 2 && (
          <section>
            <TwoCol
              col1={<OptionalInheritorsWizardContainer />}
              col2={<AmountWizardContainer />}
            />

            <Box mt={6}>
              <StepperActions prevStep={1} nextStep={3} setStep={setStep} />
            </Box>
          </section>
        )}

        {/* Step 3 content */}
        {activeStep === 3 && (
          <section>
            {/* Results */}
            <ResultsContainer />

            {/* Contact */}
            <Box mt={6}>
              <ContactContainer />
            </Box>

            {/* Disclaimer */}
            <Box layerStyle="containerPad" mt={10} mb={6}>
              <Divider />
            </Box>

            <TwoCol
              stackProps={{
                spacing: {
                  base: 0,
                  lg: 12,
                  xl: 16
                }
              }}
              col1={
                <Text textStyle="paragraph-sm" color="gray.500">
                  <Icon as={FiAlertTriangle} /> {t("text_step_3")}
                </Text>
              }
              col2={null}
            />
          </section>
        )}
      </Box>
    </Box>
  );
};

export default Stepper;
