import { Box } from "@chakra-ui/react";
import React from "react";

export interface TopActionsProps {}

const TopActions: React.FC<TopActionsProps> = ({ children }) => {
  return (
    <Box position="absolute" right={0} top={0}>
      {children}
    </Box>
  );
};

export default TopActions;
