import textStyles from "../textStyles";

const checkbox = {
  sizes: {
    sm: {
      label: {
        fontSize: textStyles["paragraph-sm"].fontSize,
      },
    },
  },
};

export default checkbox;
