import { Button, Flex, Spacer } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { SetterOrUpdater } from "recoil";
import { Step } from "../models/steps";
import FixFontShift from "./FixFontShift";

export interface StepperActionsProps {
  nextStep?: Step;
  prevStep?: Step;
  setStep: SetterOrUpdater<Step>;
}

const StepperActions: React.FC<StepperActionsProps> = ({
  nextStep,
  prevStep,
  setStep,
}) => {
  const { t } = useTranslation();

  return (
    <Flex>
      {/* Prev button */}
      {!!prevStep && (
        <Button
          size="lg"
          onClick={(e) => {
            setStep(prevStep);
            e.currentTarget.blur();
          }}
        >
          <FixFontShift>{t("prev")}</FixFontShift>
        </Button>
      )}

      <Spacer minW={2} />

      {/* Nextbutton */}
      {!!nextStep && (
        <Button
          size="lg"
          colorScheme="blue"
          onClick={(e) => {
            setStep(nextStep);
            e.currentTarget.blur();
          }}
        >
          <FixFontShift>{t("next")}</FixFontShift>
        </Button>
      )}
    </Flex>
  );
};

export default StepperActions;
