import { FormikConfig, FormikValues } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import * as Yup from "yup";
import { de as yupLocaleDe } from "yup-locales";
import { yupLocaleMap } from "../i18n";
import { acFormSucceededState, acUserState } from "../state";
import ContactComplete from "./ContactComplete";

interface FormValues {
  gender: "" | "f" | "m";
  firstName: string;
  lastName: string;
}

export interface ContactCompleteContainerProps {}

const ContactCompleteContainer: React.FC<
  ContactCompleteContainerProps
> = () => {
  const { t, i18n } = useTranslation();

  const [succeeded, setSucceeded] = useRecoilState(acFormSucceededState);

  // Get ActiveCampaign user (mandatory at this point)
  const [acUser, setAcUser] = useRecoilState(acUserState);
  if (!acUser) {
    return null;
  }

  const acUserFieldValue = (id: string) =>
    acUser.fieldValues.find(({ field }: any) => field === id)?.value;

  const initialValues: FormValues = {
    gender: acUserFieldValue("19") ?? "",
    firstName: acUser.contact.firstName ?? "",
    lastName: acUser.contact.lastName ?? "",
  };

  Yup.setLocale(yupLocaleMap.get(i18n.language) ?? yupLocaleDe);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().min(2).max(50).label(t("first_name")),
    lastName: Yup.string().min(2).max(50).label(t("last_name")),
  });

  const formikProps: FormikConfig<FormikValues> = {
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      // Create/update user through serverless function
      fetch(`/.netlify/functions/sync-user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: acUser.contact.email,
          ...values,
        }),
      })
        .then((response) => response.json())
        .then((user) => {
          // Set user to state, if found
          !!user.contact && setAcUser(user);

          // Done
          setSucceeded(true);
          setSubmitting(false);
        })
        .catch((error) => {
          console.error(error);
          setSubmitting(false);
        });
    },
  };

  return <ContactComplete formikProps={formikProps} succeeded={succeeded} />;
};

export default ContactCompleteContainer;
