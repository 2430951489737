
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Text } from "@chakra-ui/react";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text textStyle="paragraph-sm" color="gray.500" mt={4} mdxType="Text">
  Ihre Daten behandeln wir streng vertraulich und geben sie nicht an Dritte
  weiter. Mehr zum 
  <Text as="a" textDecoration="underline" href="https://www.paraplegie.ch/de/rechtliches/" target="_blank" mdxType="Text">
    Datenschutz
  </Text>
   der Paraplegiker-Stiftung.
    </Text>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;