import {
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Icon,
  Input,
  Select,
  Stack,
  VStack,
} from "@chakra-ui/react";
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikConfig,
  FormikValues,
} from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { FiCheckCircle } from "react-icons/fi";
import FixFontShift from "./FixFontShift";
import TwoCol from "./TwoCol";

export interface ContactCompleteProps {
  formikProps: FormikConfig<FormikValues>;
  succeeded?: boolean;
}

const ContactComplete: React.FC<ContactCompleteProps> = ({
  formikProps,
  succeeded = false,
}) => {
  const { t } = useTranslation();

  const IntroText = t("contact_complete_intro");

  return (
    <Formik {...formikProps}>
      {({ isSubmitting }) => (
        <Form>
          <VStack spacing={3} alignItems="stretch">
            <Heading as="h3" textStyle="h3" w="full" layerStyle="containerPad">
              {t(
                succeeded
                  ? "heading_contact_complete_succeeded"
                  : "heading_contact_complete"
              )}
            </Heading>

            {!succeeded && (
              <TwoCol
                stackProps={{
                  spacing: {
                    base: 6,
                    lg: 12,
                    xl: 16,
                  },
                }}
                col1={
                  <Stack spacing={3}>
                    <IntroText />
                  </Stack>
                }
                col2={
                  <Stack spacing={3}>
                    {/* Gender */}
                    <Field name="gender">
                      {({ field, form }: FieldProps) => (
                        <FormControl
                          isInvalid={
                            !!form.errors.gender && !!form.touched.gender
                          }
                        >
                          <Select
                            {...field}
                            id="gender"
                            placeholder={t("title") ?? undefined}
                            size="lg"
                            variant="filled"
                          >
                            <option value="m">{t("title_male")}</option>
                            <option value="f">{t("title_female")}</option>
                          </Select>

                          <FormErrorMessage>
                            {form.errors.firstName}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    {/* First name */}
                    <Field name="firstName">
                      {({ field, form }: FieldProps) => (
                        <FormControl
                          isInvalid={
                            !!form.errors.firstName && !!form.touched.firstName
                          }
                        >
                          <Input
                            {...field}
                            size="lg"
                            variant="filled"
                            id="firstName"
                            placeholder={t("first_name") ?? undefined}
                          />
                          <FormErrorMessage>
                            {form.errors.firstName}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    {/* Last name */}
                    <Field name="lastName">
                      {({ field, form }: FieldProps) => (
                        <FormControl
                          isInvalid={
                            !!form.errors.lastName && !!form.touched.lastName
                          }
                        >
                          <Input
                            {...field}
                            size="lg"
                            variant="filled"
                            id="lastName"
                            placeholder={t("last_name") ?? undefined}
                          />
                          <FormErrorMessage>
                            {form.errors.lastName}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    {/* Submit */}
                    <Button
                      alignSelf="flex-start"
                      type="submit"
                      isLoading={isSubmitting}
                      size="lg"
                      colorScheme={succeeded ? "green" : "red"}
                      rightIcon={
                        succeeded ? <Icon as={FiCheckCircle} /> : undefined
                      }
                    >
                      <FixFontShift>
                        {t(
                          succeeded ? "completed_profile" : "complete_profile"
                        )}
                      </FixFontShift>
                    </Button>
                  </Stack>
                }
              />
            )}
          </VStack>
        </Form>
      )}
    </Formik>
  );
};

export default ContactComplete;
