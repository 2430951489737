const layerStyles = {
  containerPad: {
    px: {
      sm: 12,
      md: 20,
      lg: 12,
      xl: 24,
    },
  },
};

export default layerStyles;
