import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import { useAcUserParam } from "../hooks/useAcUserParam";
import { stepState } from "../state";
import StepperContainer from "./StepperContainer";

export interface AppProps {}

const App: React.FC<AppProps> = () => {
  // Scroll root into view on step change
  const ref = useRef<HTMLDivElement>(null!);
  const step = useRecoilValue(stepState);
  useEffect(() => {
    window.requestAnimationFrame(() =>
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" })
    );
  }, [step]);

  // Check ActiveCampaign user param
  useAcUserParam();

  return (
    <Box p="5" bgColor="white" ref={ref}>
      <StepperContainer />
    </Box>
  );
};

export default App;
