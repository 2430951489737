
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Text } from "@chakra-ui/react";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text textStyle="paragraph-sm" mdxType="Text">
  Die gesetzliche Erbfolge berücksichtigt zuerst die Nachkommen (1. Parentel).
  Wenn jemand keine Nachkommen hat, erben die Eltern sowie deren Nachkommen (2.
  Parentel). Sofern es auch keine Erben der 2. Parentel gibt, erben die
  Grosseltern und ihre Nachkommen (3. Parentel). Die Personen der 1. Parentel,
  überlebende Ehegatten, eingetragene Partner*innen sowie die Eltern sind
  pflichtteilgeschützt.
    </Text>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;