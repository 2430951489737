const typograhy = {
  fonts: {
    body: "Frutiger, sans-serif",
    heading: null,
  },

  fontSizes: {
    xs: "0.75rem", // 12px
    sm: "0.875rem", // 14px
    md: "1rem", // 16px
    lg: "1.125rem", // 18px
    xl: "1.25rem", // 20px
    "2xl": "1.625rem", // 26px
  },
};

export default typograhy;
