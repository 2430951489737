import { FormControl, Heading, Input, VStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { amountFormat } from "../constants";

export interface AmountWizardProps {
  amount?: number;
  handleAmountChange: (values: NumberFormatValues) => void;
}

const AmountWizard: React.FC<AmountWizardProps> = ({
  amount,
  handleAmountChange,
}) => {
  const { t } = useTranslation();
  const Body = t("text_amount");

  return (
    <VStack spacing={3} alignItems="flex-start">
      {/* Intro */}
      <Heading as="h3" textStyle="h3">
        {t("heading_amount")}
      </Heading>
      <Body />

      {/* Form */}
      <FormControl>
        <NumberFormat
          customInput={Input}
          variant="filled"
          value={amount}
          onValueChange={handleAmountChange}
          {...amountFormat}
        />
      </FormControl>
    </VStack>
  );
};

export default AmountWizard;
