const button = {
  baseStyle: {
    borderRadius: "none",
    fontWeight: 700,
  },
  variants: {
    solid: variantSolid,
  },
};

export default button;

function variantSolid(props) {
  const { colorScheme: c } = props;

  let color = undefined;
  let bgColor = undefined;
  let hoverColor = undefined;
  let bgHoverColor = undefined;

  // Special color schemes
  if (c === "blue") {
    color = "white";
    bgColor = "blue.800";
    hoverColor = "white";
    bgHoverColor = "blue.700";
  }

  return {
    color,
    bg: bgColor,
    _hover: {
      color: hoverColor,
      bg: bgHoverColor,
    },
    _active: {
      color: hoverColor,
      bg: bgHoverColor,
    },
  };
}
