
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Text } from "@chakra-ui/react";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text textStyle="paragraph-sm" mdxType="Text">
  Si vous souhaitez favoriser une ou plusieurs personnes proches ou une ou
  plusieurs institutions, il faut le déterminer sous forme d’une disposition
  pour cause de mort (testament ou contrat de succession).
    </Text>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;