import { atom, selector } from "recoil";
import {
  calculatePortions,
  calculateShares,
  replaceSelectedInheritors,
} from "./domain/inheritance";
import { Inheritor, OptionalInheritor, Shares } from "./models/inheritance";
import { Step } from "./models/steps";

// Selected inheritors
export const selectedInheritorsState = atom<Set<Inheritor>>({
  key: "selectedInheritorsState",
  default: new Set([Inheritor.solo]),
});

// Result inheritors
export const inheritorsState = selector<Set<Inheritor>>({
  key: "inheritorsState",
  get: ({ get }) => replaceSelectedInheritors(get(selectedInheritorsState)),
});

// Selected optional inheritors
export const optionalInheritorsState = atom<Set<OptionalInheritor>>({
  key: "optionalInheritorsState",
  default: new Set(),
});

export const sharesState = selector<Shares>({
  key: "sharesState",
  get: ({ get }) => calculatePortions(calculateShares(get(inheritorsState))),
});

// Active step
export const stepState = atom<Step>({
  key: "stepState",
  default: Step.MANDATORY,
});

// Inheritance amount
export const amountState = atom<number | undefined>({
  key: "amountState",
  default: undefined,
});

// ActiveCampaign user
// via param
export const acUserParamState = atom<{ [key: string]: any } | undefined>({
  key: "acUserParamState",
  default: undefined,
});

// via form
export const acUserState = atom<{ [key: string]: any } | undefined>({
  key: "acUserState",
  default: undefined,
});

// Form submit success
export const acFormSucceededState = atom<boolean>({
  key: "acFormSucceededState",
  default: false,
});
