
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Text } from "@chakra-ui/react";
import InfoPopover from "../../../components/InfoPopover";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text textStyle="paragraph-sm" mdxType="Text">
  Vous souhaitez savoir quelle part de votre patrimoine est réservée aux
  héritiers réservataires et quelle est la quotité disponible? Vos données ne
  sont pas enregistrées et ne servent qu’à l’illustration.
    </Text>
    <Text textStyle="paragraph-sm" mdxType="Text">
  <Text as="span" whiteSpace="nowrap" mdxType="Text">
    Patrimoine{" "}
    <InfoPopover content="La succession inclut l’intégralité de la propriété et l’intégralité du patrimoine de la personne défunte. Cela comprend aussi, par exemple, les effets personnels comme les habits, les bijoux et les livres. Mais aussi tous les autres actifs comme les liquidités, placements, droits provenant de prestations de prévoyance, immeubles et terrains en font partie." inline mdxType="InfoPopover" />
  </Text>{" "}
  estimé:
    </Text>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;