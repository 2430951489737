import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Icon,
  Input,
  InputElementProps,
  InputGroup,
  InputLeftElement,
  Stack,
  VStack,
} from "@chakra-ui/react";
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikConfig,
  FormikValues,
} from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { FiMail } from "react-icons/fi";
import FixFontShift from "./FixFontShift";
import TwoCol from "./TwoCol";

export interface ContactProps {
  formikProps: FormikConfig<FormikValues>;
  spsSelected: boolean;
}

const Contact: React.FC<ContactProps> = ({ formikProps, spsSelected }) => {
  const { t } = useTranslation();

  const iconStyle: InputElementProps = {
    color: "gray.400",
    pointerEvents: "none",
  };

  const ContactPrivacyText = t("contact_privacy");

  return (
    <Formik {...formikProps}>
      {({ isSubmitting }) => (
        <Form>
          <VStack spacing={3} alignItems="flex-start">
            <TwoCol
              stackProps={{
                spacing: {
                  base: 4,
                  lg: 12,
                  xl: 16,
                },
                w: "full",
              }}
              col1={
                <Heading as="h3" textStyle="h3" w="full">
                  {t("heading_contact")}
                </Heading>
              }
              col2={
                <Box>
                  <Stack
                    direction={["column", null, null, null, null, "row"]}
                    alignItems="flex-start"
                    spacing={4}
                  >
                    {/* Mail */}
                    <Field type="email" name="email">
                      {({ field, form }: FieldProps) => (
                        <FormControl
                          isInvalid={
                            !!form.errors.email && !!form.touched.email
                          }
                        >
                          <InputGroup size="lg">
                            <InputLeftElement
                              {...iconStyle}
                              children={<Icon as={FiMail} />}
                            />
                            <Input
                              {...field}
                              variant="filled"
                              id="email"
                              placeholder={t("email") ?? undefined}
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    {/* Submit */}
                    <Button
                      type="submit"
                      colorScheme="red"
                      isLoading={isSubmitting}
                      flexShrink={0}
                      size="lg"
                    >
                      <FixFontShift>{t("contact_cta_text")}</FixFontShift>
                    </Button>
                  </Stack>
                  <ContactPrivacyText />
                </Box>
              }
            />
          </VStack>
        </Form>
      )}
    </Formik>
  );
};

export default Contact;
