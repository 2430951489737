import { Box, useToken } from "@chakra-ui/react";
import { ComputedDatum, ResponsivePie } from "@nivo/pie";
import { BasicTooltip } from "@nivo/tooltip";
import React from "react";
import { nivoColors } from "../constants";

const PieTooltip = <RawDatum,>({
  datum,
}: {
  datum: ComputedDatum<RawDatum>;
}) => (
  <BasicTooltip
    id={datum.label}
    value={datum.formattedValue}
    enableChip={true}
    color={datum.color}
  />
);

export interface PieProps {
  data: unknown[];
}

const Pie: React.FC<PieProps> = ({ data }) => {
  const colors = useToken("colors", nivoColors);

  return (
    <Box filter="drop-shadow(0 0 0.3rem rgba(0, 0, 0, .125))" h="full" w="full">
      <ResponsivePie
        data={data}
        enableSliceLabels={false}
        enableRadialLabels={false}
        radialLabel="label"
        valueFormat=" >-,.3~p"
        tooltip={PieTooltip}
        innerRadius={0.5}
        padAngle={1}
        cornerRadius={0}
        colors={colors}
        sliceLabelsSkipAngle={10}
      />
    </Box>
  );
};

export default Pie;
