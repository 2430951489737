import React from "react";
import { useRecoilState } from "recoil";
import { OptionalInheritor } from "../models/inheritance";
import { optionalInheritorsState } from "../state";
import InheritorsWizard from "./InheritorsWizard";

export interface OptionalInheritorsWizardContainerProps {}

const OptionalInheritorsWizardContainer: React.FC<OptionalInheritorsWizardContainerProps> = () => {
  // Get selected optional inheritors from state
  const [optionalInheritors, setOptionalInheritors] = useRecoilState(
    optionalInheritorsState
  );

  // Checkbox change handler
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Get checkbox value aka inheritor
    const inheritor = event.target.value as OptionalInheritor;

    // Prepare new inheritors set, adding or deleting the changed value
    const newInheritors = new Set(optionalInheritors);
    newInheritors.has(inheritor)
      ? newInheritors.delete(inheritor)
      : newInheritors.add(inheritor);

    // Set new inheritors set to state
    setOptionalInheritors(newInheritors);
  };

  // Get inheritor enum as array, excluding canton
  const inheritorKeys = Object.keys(OptionalInheritor) as Array<
    keyof typeof OptionalInheritor
  >;
  const checkboxValues = inheritorKeys.map((key) => OptionalInheritor[key]);

  return (
    <InheritorsWizard
      checkedInheritors={optionalInheritors}
      checkboxValues={checkboxValues}
      handleCheckboxChange={handleCheckboxChange}
      stepIdx={2}
    />
  );
};

export default OptionalInheritorsWizardContainer;
