
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Text } from "@chakra-ui/react";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text textStyle="paragraph-sm" color="gray.500" mt={4} mdxType="Text">
  Vos données sont traitées de manière strictement confidentielle et ne seront
  pas transférées à des tiers. En savoir plus à propos de la{" "}
  <Text as="a" textDecoration="underline" href="https://www.paraplegie.ch/fr/mentions-legales/" target="_blank" mdxType="Text">
    protection des données
  </Text>{" "}
  de la Fondation suisse pour paraplégiques.
    </Text>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;