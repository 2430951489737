const size = {
  lg: {
    borderRadius: "none",
  },

  md: {
    borderRadius: "none",
  },

  sm: {
    borderRadius: "none",
  },

  xs: {
    borderRadius: "none",
  },
};

const sizes = {
  lg: {
    field: size.lg,
    addon: size.lg,
  },
  md: {
    field: size.md,
    addon: size.md,
  },
  sm: {
    field: size.sm,
    addon: size.sm,
  },
  xs: {
    field: size.xs,
    addon: size.xs,
  },
};

const input = {
  sizes,
};

export default input;
