import { extendTheme } from "@chakra-ui/react";
import Alert from "./components/alert";
import Button from "./components/button";
import Checkbox from "./components/checkbox";
import Input from "./components/input";
import Select from "./components/select";
import colors from "./foundations/colors";
import typography from "./foundations/typography";
import layerStyles from "./layerStyles";
import styles from "./styles";
import textStyles from "./textStyles";

const overrides = {
  // Global style overrides
  styles,
  // Foundational style overrides
  colors,
  ...typography,
  // Component style overrides
  components: {
    Alert,
    Checkbox,
    Button,
    Input,
    Select,
  },
  // Text & layer styles
  textStyles,
  layerStyles,
};

export default extendTheme(overrides);
