import { Box } from "@chakra-ui/react";
import React from "react";

export interface FixFontShiftProps {}

const FixFontShift: React.FC<FixFontShiftProps> = ({ children }) => {
  return (
    <Box as="span" transform="translateY(.075em)">
      {children}
    </Box>
  );
};

export default FixFontShift;
