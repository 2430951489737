import {
  Box,
  Checkbox,
  CheckboxGroup,
  FormControl,
  Heading,
  VStack,
} from "@chakra-ui/react";
import { snakeCase } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { GeneralInheritor, Inheritor } from "../models/inheritance";
import InfoPopover from "./InfoPopover";

export interface InheritorWizardProps {
  checkedInheritors: Set<GeneralInheritor>;
  checkboxValues: GeneralInheritor[];
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  stepIdx: number;
}

const InheritorsWizard: React.FC<InheritorWizardProps> = ({
  checkedInheritors,
  checkboxValues,
  handleCheckboxChange,
  stepIdx,
}) => {
  const { t, i18n } = useTranslation();
  const Body = t(`text_step_${stepIdx}`);
  const Body2 = t(`text_step_${stepIdx}_2`);

  return (
    <VStack spacing={3} alignItems="flex-start">
      {/* Intro */}
      <Heading as="h3" textStyle="h3">
        {t(`heading_step_${stepIdx}`)}
      </Heading>
      <Body />

      {/* Controls */}
      <FormControl as="fieldset" textStyle="paragraph-sm" pt={3}>
        <CheckboxGroup value={Array.from(checkedInheritors)}>
          <Box
            sx={{
              columnCount: 2,
              columnWidth: "auto",
              columnGap: { base: 12, lg: 24 },
              boxDecorationBreak: "clone",
            }}
          >
            {checkboxValues.map((checkboxValue) => (
              <Checkbox
                w="full"
                key={checkboxValue}
                value={checkboxValue}
                onChange={handleCheckboxChange}
                size="sm"
                alignItems="flex-start"
                mb={2}
                disabled={
                  checkboxValue === Inheritor.solo &&
                  checkedInheritors.has(Inheritor.solo)
                }
              >
                <Box
                  as="span"
                  transform={{
                    base: "translateY(-0.14em)",
                    md: "translateY(-0.28em)",
                  }}
                  overflow="visible"
                  display="inline-block"
                >
                  {t(snakeCase(checkboxValue))}

                  {/* Optional info popovers */}
                  {i18n.exists(`hints.${snakeCase(checkboxValue)}`) && (
                    <InfoPopover
                      content={t(`hints.${snakeCase(checkboxValue)}`)}
                    />
                  )}
                </Box>
              </Checkbox>
            ))}
          </Box>
        </CheckboxGroup>
      </FormControl>

      <Body2 />
    </VStack>
  );
};

export default InheritorsWizard;
