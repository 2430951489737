import {
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FiInfo } from "react-icons/fi";

export interface InfoPopoverProps {
  content: string;
  inline?: boolean;
}

const InfoPopover: React.FC<InfoPopoverProps> = ({
  content,
  inline = false
}) => {
  const { t } = useTranslation();

  return (
    <Popover trigger="hover" placement="auto">
      <PopoverTrigger>
        <IconButton
          fontSize="1em"
          height="1.2em"
          width="1.2em"
          p={0}
          minW={0}
          icon={<Icon as={FiInfo} width={"78%"} height={"78%"} />}
          aria-label={t("more_info")}
          display="inline-flex"
          variant="ghost"
          verticalAlign="baseline"
          ml={inline ? "-0.1em" : "-1.2em"}
          transform={inline ? undefined : "translateX(1.3em)"}
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent boxShadow="base">
          <PopoverArrow />
          <PopoverBody textStyle="paragraph-sm">{content}</PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default InfoPopover;
