
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Text } from "@chakra-ui/react";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text textStyle="paragraph-sm" mdxType="Text">
  L’ordre de succession prend d’abord en compte les descendant-es (première
  parentèle). Si une personne n’a pas de descendant-es, les parents et leurs
  descendant-es héritent ensuite (deuxième parentèle). S’il n’y a pas
  d’héritières/d’héritiers de la deuxième parentèle, ce sont les grands-parents
  et leurs descendant-es qui héritent (troisième parentèle). Les personnes de la
  première parentèle, les époux survivants, les partenaires enregistré-es ainsi
  que les parents sont des héritiers réservataires.
    </Text>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;