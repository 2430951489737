
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Text } from "@chakra-ui/react";
import InfoPopover from "../../../components/InfoPopover";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text textStyle="paragraph-sm" mdxType="Text">
  Wählen Sie nachfolgend jene Personen oder Institutionen aus, die Sie gerne
  begünstigen möchten (mittels Testament oder Erbvertrag). Ohne anderslautende
  Regelung (z.B. im Testament oder Erbvertrag) haben diese Personen keinen
  gesetzlichen Anspruch auf Ihr Erbe und sind nicht pflichtteilgeschützt.
    </Text>
    <Text textStyle="paragraph-sm" mdxType="Text">
  Ich möchte folgende Personen oder Institutionen als Erbe einsetzen oder mit
  einem{" "}
  <Text as="span" whiteSpace="nowrap" mdxType="Text">
    Vermächtnis{" "}
    <InfoPopover content="Mit einem Vermächtnis (oder Legat) vermachen Sie einer Person oder einer Institution den ganzen oder einen Teil Ihrer freien Quote. Das Vermächtnis ist einem Erbe ähnlich, setzt den Empfänger des Vermächtnisses aber nicht als Erben ein. Für die Auszahlung oder Übergabe des Legats an den Empfänger sind grundsätzlich die Erben zuständig." inline mdxType="InfoPopover" />
  </Text>{" "}
  begünstigen:
    </Text>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;