const textStyles = {
  h3: {
    fontSize: ["xl", null, "2xl"],
    lineHeight: "shorter",
    color: "blue.800"
  },
  paragraph: {
    fontSize: ["md", null, "lg"],
    lineHeight: "tall"
  },
  "paragraph-sm": {
    fontSize: "lg",
    lineHeight: "short"
  }
};

export default textStyles;
