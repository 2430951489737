import React from "react";
import { useRecoilState } from "recoil";
import { Step } from "../models/steps";
import { stepState } from "../state";
import Stepper from "./Stepper";

export interface StepperContainerProps {}

const StepperContainer: React.FC<StepperContainerProps> = () => {
  const [step, setStep] = useRecoilState(stepState);
  const steps = [Step.MANDATORY, Step.OPTIONAL, Step.RESULTS];

  return <Stepper steps={steps} activeStep={step} setStep={setStep} />;
};

export default StepperContainer;
