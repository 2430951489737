
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Text } from "@chakra-ui/react";
import InfoPopover from "../../../components/InfoPopover";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text textStyle="paragraph-sm" mdxType="Text">
  Di seguito selezionate le persone o istituzioni che vorreste favorire (tramite
  testamento o contratto successorio). In assenza di disposizioni contrarie (ad
  esempio in un testamento o in un contratto successorio), queste persone non
  hanno alcun diritto legale sulla vostra eredità e non sono tutelate da
  porzioni legittime.
    </Text>
    <Text textStyle="paragraph-sm" mdxType="Text">
  Desidero nominare le seguenti persone o istituzioni come miei eredi o
  favorirle con un{" "}
  <Text as="span" whiteSpace="nowrap" mdxType="Text">
    lascito{" "}
    <InfoPopover content="Con un legato (lascito) lasciate in eredità a una persona o a un’istituzione l’intera quota disponibile o una parte di essa. Un lascito è simile all’eredità, ma il destinatario del lascito non è un erede. In linea di massima, spetta agli eredi occuparsi del versamento o del trasferimento del lascito." inline mdxType="InfoPopover" />
  </Text>
  :
    </Text>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;