
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Text } from "@chakra-ui/react";
import InfoPopover from "../../../components/InfoPopover";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text textStyle="paragraph-sm" mdxType="Text">
  Sie wollen herausfinden, welcher Anteil Ihres Nachlassvermögens von Gesetztes
  wegen pflichtteilgeschützt ist und wie hoch die freie Quote ist? Ihre Daten
  werden nicht gespeichert und dienen rein zur Veranschaulichung.
    </Text>
    <Text textStyle="paragraph-sm" mdxType="Text">
  Geschätztes{" "}
  <Text as="span" whiteSpace="nowrap" mdxType="Text">
    Nachlassvermögen:{" "}
    <InfoPopover content="Zum Nachlass gehören der gesamte Besitz und das gesamte Vermögen einer verstorbenen Person. Das sind beispielsweise die persönlichen Habseligkeiten wie Kleidung, Schmuck und Bücher. Aber auch andere Vermögenswerte wie Barvermögen, Anlagevermögen, Ansprüche aus Vorsorgeleistungen, Grundstücke sowie Immobilien zählen dazu." inline mdxType="InfoPopover" />
  </Text>
    </Text>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;