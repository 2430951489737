import {
  AspectRatio,
  Box,
  Heading,
  HStack,
  List,
  ListItem,
  Stack,
  Text,
} from "@chakra-ui/react";
import { OrdinalColorScale } from "@nivo/colors";
import React from "react";
import NumberFormat from "react-number-format";
import { amountFormat, percentageFormat } from "../constants";
import Pie from "./Pie";

export interface PieSectionProps {
  amount?: number;
  pieData: Array<{
    id: string;
    label: string;
    value: number;
  }>;
  getInheritorColor: OrdinalColorScale<string>;
}

const PieSection: React.FC<PieSectionProps> = ({
  amount,
  pieData,
  getInheritorColor,
}) => {
  return (
    <Stack
      direction={{
        base: "column",
        sm: "row",
      }}
      alignItems={{
        base: "flex-start",
        sm: "center",
      }}
      spacing={6}
    >
      {/* Chart */}
      <AspectRatio
        flexShrink={0}
        ratio={1}
        w={{
          base: "full",
          sm: 0.5,
        }}
        maxW={60}
      >
        <Box overflow="visible !important">
          <Pie data={pieData} />
        </Box>
      </AspectRatio>

      {/* Legend */}
      <List spacing={2}>
        {pieData.map(({ id, label, value }) => (
          <ListItem key={id}>
            <HStack alignItems="flex-start" spacing={3}>
              <Box
                height={5}
                width={5}
                bgColor={getInheritorColor(id)}
                flexShrink={0}
                borderRadius="none"
              />
              <Box>
                <Heading as="h4" fontSize="md">
                  {label}
                </Heading>
                <Text fontSize="md">
                  {/* Percentage */}
                  <NumberFormat value={value * 100} {...percentageFormat} />

                  {/* Amount */}
                  {!!amount && (
                    <>
                      ,{" "}
                      <NumberFormat
                        value={amount * value}
                        displayType="text"
                        {...amountFormat}
                      />
                    </>
                  )}
                </Text>
              </Box>
            </HStack>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

export default PieSection;
