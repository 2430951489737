
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Text } from "@chakra-ui/react";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text textStyle="paragraph-sm" color="gray.500" mt={4} mdxType="Text">
  I vostri dati sono trattati in maniera strettamente confidenziale e non
  vengono trasmessi a terzi. Per saperne di più sulla{" "}
  <Text as="a" textDecoration="underline" href="https://www.paraplegie.ch/it/informazione-legale/" target="_blank" mdxType="Text">
    protezione dei dati
  </Text>{" "}
  della Fondazione svizzera per paraplegici.
    </Text>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;