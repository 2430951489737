
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Text } from "@chakra-ui/react";
import InfoPopover from "../../../components/InfoPopover";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text textStyle="paragraph-sm" mdxType="Text">
  Sélectionnez ci-après les personnes ou institutions que vous souhaitez
  favoriser (dans un testament ou un contrat de succession). Sans disposition
  contraire (p. ex. par un testament ou un contrat de succession), ces personnes
  n’ont pas droit à votre succession et ne reçoivent donc pas de part
  réservataire.
    </Text>
    <Text textStyle="paragraph-sm" mdxType="Text">
  Je souhaite désigner les personnes ou institutions suivantes comme héritières
  ou les favoriser par un{" "}
  <Text as="span" whiteSpace="nowrap" mdxType="Text">
    legs{" "}
    <InfoPopover content="Vous pouvez léguer une partie ou l’intégralité de votre quotité disponible à une personne ou une institution. Le legs est similaire à un héritage, mais la/le destinataire du legs n’est pas héritière/héritier. Les héritières/héritiers sont en principe responsables du paiement ou de la remise du legs." inline mdxType="InfoPopover" />
  </Text>
  :
    </Text>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;