
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Text } from "@chakra-ui/react";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text textStyle="paragraph-sm" mdxType="Text">
  Vorremmo rivolgerci a voi per nome. Se siete d’accordo, potete completare il
  vostro profilo:
    </Text>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;