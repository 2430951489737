import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { acUserParamState } from "../state";

// Fetch ActiveCampaign user using a query parameter and set it to state on success
export const useAcUserParam = () => {
  const setAcUserParam = useSetRecoilState(acUserParamState);

  useEffect(() => {
    // Get ActiveCampaign user id from query params (either on parent or self)
    const urlParams = new URLSearchParams(window.location.search);
    let parentUrlParams;

    // Getting parent query params might throw a CORS error
    try {
      parentUrlParams = new URLSearchParams(window.parent.location.search);
    } catch (error) {}

    const userId =
      urlParams.get("activecampaign_id") ??
      parentUrlParams?.get("activecampaign_id");

    userId &&
      // Fetch AC API through serverless function
      fetch(`/.netlify/functions/get-user?id=${userId}`)
        .then((response) => response.json())
        .then((user) => {
          // Set user to state, if found
          !!user.contact && setAcUserParam(user);
        });
  }, [setAcUserParam]);
};
