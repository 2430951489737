import { Inheritor, Shares } from "../models/inheritance";

/**
 * Calculates shares as defined by swiss law. Used as-is if no testament is present.
 * @param {Set<Inheritor>} inheritors - The selected inheritors to define the shares for.
 * @param {number} whole - The base value used as `100%` in the calculation. (optional)
 */
export const calculateShares = (
  inheritors: Set<Inheritor>,
  whole: number = 1 // 100%
): Map<Inheritor, number> => {
  // Create result map
  const shares = new Map<Inheritor, number>();

  // FIRST PARENTELA
  // Check if first parentela actors are present
  if (inheritors.has(Inheritor.descendants)) {
    // If partner is present => partner gets half
    inheritors.has(Inheritor.partner) &&
      shares.set(Inheritor.partner, whole * 0.5);

    // Descendants (first parentela members) share
    // - either the other half
    // - or everything if partner isn't present
    const parentelaShare = inheritors.has(Inheritor.partner)
      ? whole * 0.5
      : whole;

    // Calculate shares between members of first parentela
    inheritors.has(Inheritor.descendants) &&
      shares.set(Inheritor.descendants, parentelaShare);

    // SECOND PARENTELA
    // No first parentela members present, check second parentela
  } else if (
    inheritors.has(Inheritor.mother) ||
    inheritors.has(Inheritor.father) ||
    inheritors.has(Inheritor.closeRelatives)
  ) {
    // If partner is present => partner gets three quarters
    inheritors.has(Inheritor.partner) &&
      shares.set(Inheritor.partner, whole * 0.75);

    // Second parentela members share
    // - either the other quarter
    // - or everything if partner isn't present
    const parentelaShare = inheritors.has(Inheritor.partner)
      ? whole * 0.25
      : whole;

    // Calculate shares between members of second parentela
    const parents = new Set(
      Array.from(inheritors).filter((inheritor) =>
        [Inheritor.mother, Inheritor.father].includes(inheritor)
      )
    );

    const parentsShare =
      parents.size === 2
        ? parentelaShare
        : parents.size === 1
        ? inheritors.has(Inheritor.closeRelatives)
          ? parentelaShare / 2
          : parentelaShare
        : 0;

    const closeRelativesShare = inheritors.has(Inheritor.closeRelatives)
      ? parentelaShare - parentsShare
      : 0;

    // Parents share
    parentsShare &&
      parents.forEach((parent) => {
        shares.set(parent, parentsShare / parents.size);
      });

    // Close relatives share
    closeRelativesShare &&
      shares.set(Inheritor.closeRelatives, closeRelativesShare);

    // THIRD PARENTELA
    // No second parentela members present, check third parentela
  } else if (inheritors.has(Inheritor.otherRelatives)) {
    // If partner is present => partner gets everything
    if (inheritors.has(Inheritor.partner)) {
      shares.set(Inheritor.partner, whole);

      // No partner present, share everything between third parentela members
    } else {
      shares.set(Inheritor.otherRelatives, whole);
    }

    // PARTNER ONLY
    // No third parentela members present, check partner
  } else if (inheritors.has(Inheritor.partner)) {
    shares.set(Inheritor.partner, whole);

    // CANTON
    // Noone left, canton gets everything
  } else {
    shares.set(Inheritor.canton, whole);
  }

  return shares;
};

/**
 * Calculates statutory and disposable portions for a map of inheritors and their respective shares.
 * @param {Map<Inheritor, number>} shares - The shares for a set of inheritors, as defined by swiss law.
 */
export const calculatePortions = (shares: Map<Inheritor, number>): Shares => {
  // Build statutory portions map
  const statutoryPortions = new Map<Inheritor, number>([
    [Inheritor.partner, 0.5],
    [Inheritor.descendants, 0.5],
  ]);

  // Extend shares map with statutary and disposable portions
  return new Map(
    Array.from(shares).map(([inheritor, share]) => [
      inheritor,
      {
        share,
        statutoryPortion: share * (statutoryPortions.get(inheritor) ?? 0),
        disposablePortion:
          share * (1 - (statutoryPortions.get(inheritor) ?? 0)),
      },
    ])
  );
};

/**
 * Replaces some selected inheritors with their group counterpart in order to create result groups.
 * @param {Set<Inheritor>} inheritors - The inheritors to be replaced with their group counterparts.
 */
export const replaceSelectedInheritors = (
  inheritors: Set<Inheritor>
): Set<Inheritor> => {
  const replacements = new Map<Inheritor, Inheritor>([
    [Inheritor.children, Inheritor.descendants],
    [Inheritor.grandChildren, Inheritor.descendants],
    [Inheritor.siblings, Inheritor.closeRelatives],
    [Inheritor.nieces, Inheritor.closeRelatives],
    [Inheritor.auntUncle, Inheritor.otherRelatives],
    [Inheritor.grandParents, Inheritor.otherRelatives],
    [Inheritor.cousins, Inheritor.otherRelatives],
  ]);

  return new Set(
    Array.from(inheritors).map(
      (inheritor) => replacements.get(inheritor) ?? inheritor
    )
  );
};
