import { css, Global } from "@emotion/react";
import React from "react";

import fontFrutiger300Woff from "../fonts/35A32A_2_0-025fa958221af01892d9b408f6ab0abc.woff";
import fontFrutiger300Woff2 from "../fonts/35A32A_2_0-ecfc321f0ba1ba64e4b0df90de153b1d.woff2";
import fontFrutiger400Woff from "../fonts/35A32A_1_0-976f6d4536155d460e260eb7dd2ec434.woff";
import fontFrutiger400Woff2 from "../fonts/35A32A_1_0-74d5c0393a97b044fad1f10d7a06260a.woff2";
import fontFrutiger700Woff from "../fonts/35A32A_0_0-209d1d9e02b1bc38448a6fbeb26deed3.woff";
import fontFrutiger700Woff2 from "../fonts/35A32A_0_0-2edd6f4d3bf442eec48dfea33fb98ade.woff2";

export const Fonts = () => (
  <Global
    styles={css`
      @font-face {
        font-family: "Frutiger";
        src: url(${fontFrutiger300Woff2}) format("woff2"),
          url(${fontFrutiger300Woff}) format("woff");
        font-weight: 300;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: "Frutiger";
        src: url(${fontFrutiger400Woff2}) format("woff2"),
          url(${fontFrutiger400Woff}) format("woff");
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: "Frutiger";
        src: url(${fontFrutiger700Woff2}) format("woff2"),
          url(${fontFrutiger700Woff}) format("woff");
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
    `}
  />
);
