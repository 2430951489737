import { NumberFormatProps } from "react-number-format";

export const percentageFormat: NumberFormatProps = {
  decimalScale: 3,
  displayType: "text",
  suffix: "%",
};

export const amountFormat: NumberFormatProps = {
  suffix: " CHF",
  decimalScale: 0,
  thousandSeparator: true,
  allowLeadingZeros: false,
  allowNegative: false,
};

export const nivoColors = [
  "blue.800",
  "blue.500",
  "blue.300",
  "blue.200",
  "blue.100",
];
