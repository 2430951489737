const colors = {
  red: {
    50: "#FCE8ED",
    100: "#F7BFCC",
    200: "#F396AB",
    300: "#EE6D8B",
    400: "#E9446A",
    500: "#E5224f", // palette based on this CI color
    600: "#B7153B",
    700: "#89102C",
    800: "#5B0B1D",
    900: "#2E050F",
  },
  blue: {
    50: "#E5F3FF",
    100: "#B8DEFF",
    200: "#8AC8FF",
    300: "#5CB3FF",
    400: "#2E9DFF",
    500: "#0088FF",
    600: "#0070AF", // CI color
    700: "#034a88", // CI color
    800: "#00417A", // CI color
    900: "#001B33",
  },
};

export default colors;
