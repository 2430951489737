import { Alert, AlertIcon, Box, Heading, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { amountFormat, percentageFormat } from "../constants";
import PieSectionContainer from "./PieSectionContainer";
import TwoCol from "./TwoCol";

export interface ResultsProps {
  amount?: number;
  totalDisposablePortion: number;
  sharesPieData: Array<{
    id: string;
    label: string;
    value: number;
  }>;
  portionsPieData: Array<{
    id: string;
    label: string;
    value: number;
  }>;
  optionalInheritors: string[];
}

const Results: React.FC<ResultsProps> = ({
  amount,
  totalDisposablePortion,
  sharesPieData,
  portionsPieData,
  optionalInheritors,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <TwoCol
        col1Props={{ flexGrow: 1, display: "flex", flexDir: "column" }}
        col2Props={{ flexGrow: 1, display: "flex", flexDir: "column" }}
        col1={
          <>
            {/* Intro */}
            <VStack spacing={3} alignItems="flex-start">
              <Heading as="h3" textStyle="h3">
                {t("heading_statutory_portions")}
              </Heading>
              <Text textStyle="paragraph-sm">
                {t("text_statutory_portions")}
              </Text>
            </VStack>

            {/* Chart */}
            <Box py={{ base: 8, lg: 16, xl: 20 }} mt="auto">
              <PieSectionContainer pieData={sharesPieData} />
            </Box>
          </>
        }
        col2={
          <>
            {/* Intro */}
            <VStack spacing={3} alignItems="flex-start">
              <Heading as="h3" textStyle="h3">
                {t("heading_disposable_portions")}
              </Heading>
              <Text textStyle="paragraph-sm">
                {t("text_disposable_portions")}
              </Text>
            </VStack>

            {/* Chart */}
            <Box py={{ base: 8, lg: 16, xl: 20 }} mt="auto">
              <PieSectionContainer pieData={portionsPieData} />
            </Box>
          </>
        }
      />

      {/* Text */}
      {!!optionalInheritors.length && (
        <TwoCol
          stackProps={{
            mt: { base: -4, sm: 0, lg: -16 / 2, xl: -20 / 2 },
            pb: { base: 4, lg: 16, xl: 20 },
            spacing: {
              base: 0,
              lg: 12,
              xl: 16,
            },
          }}
          col1={null}
          col2={
            <Alert status="info" colorScheme="gray" alignItems="flex-start">
              <AlertIcon />
              <Text textStyle="paragraph-sm">
                <Trans
                  i18nKey="disposable_portion_text"
                  components={[
                    // Disposable portion percentage
                    <NumberFormat
                      value={totalDisposablePortion * 100}
                      {...percentageFormat}
                    />,

                    // Disposable portion amount (optional)
                    ...(!!amount
                      ? [
                          <NumberFormat
                            prefix=" / "
                            value={totalDisposablePortion * amount}
                            displayType="text"
                            {...amountFormat}
                          />,
                        ]
                      : [<span />]),

                    // Disposable portion inheritors
                    <span
                      dangerouslySetInnerHTML={{
                        __html: optionalInheritors.join(", "),
                      }}
                    />,
                  ]}
                />
              </Text>
            </Alert>
          }
        />
      )}
    </>
  );
};

export default Results;
