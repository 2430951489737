
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Text } from "@chakra-ui/react";
import InfoPopover from "../../../components/InfoPopover";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text textStyle="paragraph-sm" mdxType="Text">
  Desiderate sapere quale quota del vostro patrimonio ereditario è tutelata per
  legge dalle porzioni legittime e a quanto ammonta la quota disponibile? I
  vostri dati non vengono registrati e sono utilizzati solo a scopo
  illustrativo.
    </Text>
    <Text textStyle="paragraph-sm" mdxType="Text">
  Patrimonio{" "}
  <Text as="span" whiteSpace="nowrap" mdxType="Text">
    ereditario{" "}
    <InfoPopover content="La successione comprende tutti i beni e l’intero patrimonio della persona deceduta. Si tratta, ad esempio, di oggetti personali come vestiti, gioielli e libri. Ma comprende anche altri valori patrimoniali come il patrimonio in contanti, il patrimonio immobilizzato, i diritti alle prestazioni di previdenza, i terreni e i beni immobiliari." inline mdxType="InfoPopover" />{" "}
  </Text>
  stimato:
    </Text>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;