export enum Inheritor {
  partner = "partner",
  children = "children",
  grandChildren = "grandChildren",
  mother = "mother",
  father = "father",
  siblings = "siblings",
  nieces = "nieces",
  auntUncle = "auntUncle",
  grandParents = "grandParents",
  cousins = "cousins",
  descendants = "descendants",
  closeRelatives = "closeRelatives",
  otherRelatives = "otherRelatives",
  canton = "canton",
  solo = "solo",
}

export enum OptionalInheritor {
  companion = "companion",
  companionChildren = "companionChildren",
  relativeInLaw = "relativeInLaw",
  fosterChildren = "fosterChildren",
  friend = "friend",
  sps = "sps",
  otherInstitution = "otherInstitution",
}

export type GeneralInheritor = Inheritor | OptionalInheritor;

export type Shares = Map<
  Inheritor,
  {
    share: number;
    statutoryPortion: number;
    disposablePortion: number;
  }
>;
