import { Box, Button, HStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { SetterOrUpdater } from "recoil";
import { Step } from "../models/steps";

export interface StepperNavigationProps {
  steps: Step[];
  activeStep: Step;
  setStep: SetterOrUpdater<Step>;
}

const StepperNavigation: React.FC<StepperNavigationProps> = ({
  steps,
  activeStep,
  setStep
}) => {
  const { t } = useTranslation();
  return (
    <nav>
      <HStack
        as="ul"
        listStyleType="none"
        pb={4}
        borderBottomWidth={8}
        borderColor="gray.100"
      >
        {steps.map((step) => (
          <Box
            as="li"
            key={step}
            position="relative"
            _before={{
              display: step === activeStep ? "block" : "none",
              content: "''",
              position: "absolute",
              top: "100%",
              mt: 4,
              left: 0,
              right: 0,
              height: 2,
              backgroundColor: "blue.800"
            }}
          >
            <Button
              variant="ghost"
              color={step === activeStep ? "blue.800" : "gray.500"}
              transition="none"
              _focus={{ outline: "none", bgColor: "gray.100" }}
              onClick={(e) => {
                setStep(step);
                e.currentTarget.blur();
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                height={8}
                width={8}
                fontSize="xl"
                rounded="full"
                border="1px"
                borderColor={step === activeStep ? "blue.800" : "currentColor"}
                bgColor={step === activeStep ? "blue.800" : undefined}
                color={step === activeStep ? "white" : undefined}
              >
                <Box as="span" transform="translate(0.01em, .05em)">
                  {step.toString()}
                </Box>
              </Box>
              <Box
                as="span"
                display={{ base: "none", md: "block" }}
                ml={2}
                fontSize="lg"
              >
                {t(`step_${step}`)}
              </Box>
            </Button>
          </Box>
        ))}
      </HStack>
    </nav>
  );
};

export default StepperNavigation;
