import { FormikConfig, FormikValues } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import * as Yup from "yup";
import { de as yupLocaleDe } from "yup-locales";
import { yupLocaleMap } from "../i18n";
import { OptionalInheritor } from "../models/inheritance";
import {
  acUserParamState,
  acUserState,
  optionalInheritorsState,
} from "../state";
import Contact from "./Contact";
import ContactCompleteContainer from "./ContactCompleteContainer";

interface FormValues {
  email: string;
}

export interface ContactContainerProps {}

const ContactContainer: React.FC<ContactContainerProps> = () => {
  const { t, i18n } = useTranslation();
  const optionalInheritors = useRecoilValue(optionalInheritorsState);

  // Check for ActiveCampaign user param
  const acUserParam = useRecoilValue(acUserParamState);

  // Check for ActiveCampaign user
  const [acUser, setAcUser] = useRecoilState(acUserState);

  // AC user param submitted => Don't render form at all
  if (acUserParam) {
    return null;
  }

  // AC user found => Render contact-complete form
  if (acUser) {
    return <ContactCompleteContainer />;
  }

  // User not found -> proceed displaying the initial contact form
  const initialValues: FormValues = {
    email: "",
  };

  Yup.setLocale(yupLocaleMap.get(i18n.language) ?? yupLocaleDe);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required().label(t("email")),
  });

  const formikProps: FormikConfig<FormikValues> = {
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      // Create/update user through serverless function
      fetch(`/.netlify/functions/sync-user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...values,
        }),
      })
        .then((response) => response.json())
        .then((user) => {
          // Set user to state, if found
          !!user.contact && setAcUser(user);

          // Assign new user to lists (in the background)
          fetch(`/.netlify/functions/add-to-lists`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              spsSelected: optionalInheritors.has(OptionalInheritor.sps),
              id: user.contact.id,
              lang: i18n.language,
            }),
          });

          // Done
          setSubmitting(false);
        })
        .catch((error) => {
          console.error(error);
          setSubmitting(false);
        });
    },
  };

  return (
    <Contact
      formikProps={formikProps}
      spsSelected={optionalInheritors.has(OptionalInheritor.sps)}
    />
  );
};

export default ContactContainer;
