import { Box, BoxProps, Stack, StackProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export interface TwoColProps {
  col1: ReactNode;
  col1Props?: BoxProps;
  col2: ReactNode;
  col2Props?: BoxProps;
  stackProps?: StackProps;
}

const TwoCol: React.FC<TwoColProps> = ({
  col1,
  col1Props,
  col2,
  col2Props,
  stackProps,
}) => {
  return (
    <Stack
      direction={{
        base: "column",
        lg: "row",
      }}
      alignItems={{
        base: "flex-start",
        lg: "stretch",
      }}
      spacing={{
        base: 10,
        lg: 12,
        xl: 16,
      }}
      layerStyle="containerPad"
      {...stackProps}
    >
      <Box width="full" {...col1Props}>
        {col1}
      </Box>
      <Box width="full" {...col2Props}>
        {col2}
      </Box>
    </Stack>
  );
};
export default TwoCol;
