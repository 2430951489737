
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Text } from "@chakra-ui/react";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text textStyle="paragraph-sm" mdxType="Text">
  Besteht der Wunsch einer oder mehrere der erwähnten nahestehenden Personen
  oder Institutionen zu begünstigen, so muss dies in Form einer letztwilligen
  Verfügung von Todes wegen (Testament oder Erbvertrag) gemacht werden.
    </Text>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;