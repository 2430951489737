import React from "react";
import { NumberFormatValues } from "react-number-format";
import { useRecoilState } from "recoil";
import { amountState } from "../state";
import AmountWizard from "./AmountWizard";

export interface AmountWizardContainerProps {}

const AmountWizardContainer: React.FC<AmountWizardContainerProps> = () => {
  const [amount, setAmount] = useRecoilState(amountState);

  const handleAmountChange = (values: NumberFormatValues) => {
    setAmount(values.floatValue);
  };
  return (
    <AmountWizard amount={amount} handleAmountChange={handleAmountChange} />
  );
};

export default AmountWizardContainer;
