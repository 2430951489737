
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Text } from "@chakra-ui/react";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text textStyle="paragraph-sm" mdxType="Text">
  La successione legittima considera prima i discendenti (1° grado di
  parentela). Se qualcuno non ha discendenti, ereditano i genitori e i loro
  discendenti (2° grado di parentela). Se non ci sono eredi del 2° grado di
  parentela, ereditano i nonni e i loro discendenti (3° grado di parentela). Le
  persone del 1° grado di parentela, i coniugi superstiti e i genitori sono
  tutelati da porzioni legittime.
    </Text>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;