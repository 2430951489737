
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Text } from "@chakra-ui/react";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text textStyle="paragraph-sm" mdxType="Text">
  Gerne würden wir Sie mit Namen ansprechen. Wenn Sie damit einverstanden sind,
  können Sie Ihr Profil vervollständigen:
    </Text>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;