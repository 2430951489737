import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "./locales/translation";
import LanguageDetector from "i18next-browser-languagedetector";
import {
  de as yupLocaleDe,
  it as yupLocaleIt,
  fr as yupLocaleFr,
} from "yup-locales";

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    detection: {
      lookupQuerystring: "lang",
      order: ["querystring"],
    },
    fallbackLng: "de",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

export const yupLocaleMap = new Map([
  ["it", yupLocaleIt],
  ["fr", yupLocaleFr],
  ["de", yupLocaleDe],
]);
