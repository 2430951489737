import { Box, useToken } from "@chakra-ui/react";
import * as d3 from "d3";
import { motion, SVGMotionProps, Variants } from "framer-motion";
import { snakeCase } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { GeneralInheritor, Inheritor } from "../models/inheritance";

type Datum = {
  inheritor: Inheritor | null;
  value: number;
  disableInteraction?: boolean;
};

export interface InheritorsWizardCircleProps {
  checkedInheritors: Set<GeneralInheritor>;
  handleArcClick: (inheritor: Inheritor) => void;
}

const InheritorsWizardCircle: React.FC<InheritorsWizardCircleProps> = ({
  checkedInheritors,
  handleArcClick,
}) => {
  const { t, i18n } = useTranslation();

  const partnerData: Datum[] = [
    {
      inheritor: Inheritor.partner,
      value: 1,
    },
  ];

  const firstParentelaData: Datum[] = [
    {
      inheritor: Inheritor.children,
      value: 1,
    },
    {
      inheritor: Inheritor.grandChildren,
      value: 1,
    },
  ];

  const secondParentelaData: Datum[] = [
    {
      inheritor: Inheritor.siblings,
      value: 2,
    },
    {
      inheritor: Inheritor.father,
      value: 1,
    },
    {
      inheritor: Inheritor.mother,
      value: 1,
    },
    {
      inheritor: Inheritor.nieces,
      value: 2,
    },
  ];

  const thirdParentelaData: Datum[] = [
    {
      inheritor: Inheritor.auntUncle,
      value: 1,
    },
    {
      inheritor: Inheritor.grandParents,
      value: 1,
    },
    {
      inheritor: Inheritor.cousins,
      value: 1,
    },
  ];

  const shadowData: Datum[] = [
    {
      inheritor: null,
      value: 1,
    },
  ];

  // Helper function to see if a datum is checked
  const isChecked = (data: Datum): boolean =>
    !!data.inheritor && checkedInheritors.has(data.inheritor);

  // Colors
  const colors = {
    primary: useToken("colors", "blue.800"),
    primaryLight: useToken("colors", "blue.700"),
    secondary: useToken("colors", "red.500"),
    shadow: useToken("colors", "black"),
  };

  // Sizes
  const width = 1000;
  const height = width;
  const radius = height / 2;
  const innerRadius = radius * 0.352;
  const arcHeight = (radius - innerRadius) / 3;
  const padding = 0.002;
  const shadowArcOffset = 1;

  // Pie functions
  const pie = d3
    .pie<Datum>()
    .startAngle(1.25 * Math.PI)
    .endAngle(1.25 * Math.PI + 1.5 * Math.PI)
    .value((d) => d.value)
    .sort(null);

  const partnerPie = d3
    .pie<Datum>()
    .startAngle(0.75 * Math.PI)
    .endAngle(0.75 * Math.PI + 0.5 * Math.PI)
    .value((d) => d.value)
    .sort(null);

  // Arc configuration
  const arcLines = new Map<
    "1" | "1.5" | "2" | "2.5" | "3" | "3.5" | "4",
    number
  >([
    ["1", innerRadius],
    ["1.5", innerRadius + arcHeight * 0.5],
    ["2", innerRadius + arcHeight * 1],
    ["2.5", innerRadius + arcHeight * 1.5],
    ["3", innerRadius + arcHeight * 2],
    ["3.5", innerRadius + arcHeight * 2.5],
    ["4", innerRadius + arcHeight * 3],
  ]);

  const arcs = new Map([
    [
      "1",
      {
        itemArc: d3
          .arc()
          .innerRadius(arcLines.get("1")!)
          .outerRadius(arcLines.get("2")!),
        shadowArc: d3
          .arc()
          .innerRadius(arcLines.get("1")! + shadowArcOffset)
          .outerRadius(arcLines.get("2")! - shadowArcOffset),
        text: d3
          .arc()
          .innerRadius(arcLines.get("1.5")!)
          .outerRadius(arcLines.get("1.5")!),
        data: firstParentelaData,
      },
    ],
    [
      "2",
      {
        itemArc: d3
          .arc()
          .innerRadius(arcLines.get("2")!)
          .outerRadius(arcLines.get("3")!),
        shadowArc: d3
          .arc()
          .innerRadius(arcLines.get("2")! + shadowArcOffset)
          .outerRadius(arcLines.get("3")! - shadowArcOffset),
        text: d3
          .arc()
          .innerRadius(arcLines.get("2.5")!)
          .outerRadius(arcLines.get("2.5")!),
        data: secondParentelaData,
      },
    ],
    [
      "3",
      {
        itemArc: d3
          .arc()
          .innerRadius(arcLines.get("3")!)
          .outerRadius(arcLines.get("4")!),
        shadowArc: d3
          .arc()
          .innerRadius(arcLines.get("3")! + shadowArcOffset)
          .outerRadius(arcLines.get("4")! - shadowArcOffset),
        text: d3
          .arc()
          .innerRadius(arcLines.get("3.5")!)
          .outerRadius(arcLines.get("3.5")!),
        data: thirdParentelaData,
      },
    ],
    [
      "partner",
      {
        itemArc: d3
          .arc()
          .innerRadius(arcLines.get("1")!)
          .outerRadius(arcLines.get("3")!),
        shadowArc: d3
          .arc()
          .innerRadius(arcLines.get("1")! + shadowArcOffset)
          .outerRadius(arcLines.get("3")! - shadowArcOffset),
        textLower: d3
          .arc()
          .innerRadius(arcLines.get("2.5")!)
          .outerRadius(arcLines.get("2.5")!),
        textMiddle: d3
          .arc()
          .innerRadius(arcLines.get("2")!)
          .outerRadius(arcLines.get("2")!),
        textUpper: d3
          .arc()
          .innerRadius(arcLines.get("1.5")!)
          .outerRadius(arcLines.get("1.5")!),
        data: partnerData,
      },
    ],
  ]);

  // Text color
  const arcGroupVariants: Variants = {
    default: {
      color: colors.primary,
    },
    hover: {
      color: "#fff",
    },
    active: {
      color: "#fff",
    },
  };

  const arcGroupProps = (data: Datum): SVGMotionProps<SVGGElement> => ({
    cursor: "pointer",
    pointerEvents: data.disableInteraction ? "none" : undefined,
    transition: { duration: 0.15 },
    variants: arcGroupVariants,
    initial: "default",
    whileHover: "hover",
    whileTap: "tap",
    animate: isChecked(data) ? "active" : "default",
    onClick: (e) => {
      !!data.inheritor && handleArcClick(data.inheritor);

      e.preventDefault();
      e.currentTarget.blur();
    },
  });

  // Background color
  const arcVariants: Variants = {
    default: {
      fill: "#fff",
    },
    hover: {
      fill: colors.primaryLight,
    },
    active: {
      fill: colors.primary,
    },
  };

  const arcProps = (data: Datum): SVGMotionProps<SVGPathElement> => ({
    transition: { duration: 0.15 },
    variants: arcVariants,
  });

  const shadowArcFill = colors.primary;
  const shadowArcProps: React.SVGProps<SVGPathElement> = {
    style: { filter: "url(#shadow)" },
    fill: shadowArcFill,
  };

  // Text animation
  const labelVariants: Variants = {
    tap: {
      dy: 2,
    },
  };

  const labelProps: SVGMotionProps<SVGTextElement> = {
    fontSize: 36,
    fontWeight: 700,
    fill: "currentColor",
    pointerEvents: "none",
    dominantBaseline: "central",
    dy: 3, // CI font shift
    transition: { duration: 0.05 },
    variants: labelVariants,
  };

  const parentelaLabelStyles: React.SVGProps<SVGTextElement> = {
    fontSize: 24,
    fill: colors.secondary,
    filter: "url(#bg-rounded)",
    pointerEvents: "none",
  };

  return (
    <Box p={{ base: 2, sm: 4 }} maxW={600} ml="auto" mr="auto">
      <nav>
        <Box as="svg" viewBox={`0 0 ${width} ${height}`} overflow="visible">
          <defs>
            {/* Arc shadow */}
            <filter id="shadow" x="-40%" width="180%" y="-40%" height="180%">
              <feDropShadow
                dx="0"
                dy="0"
                floodColor={colors.shadow}
                floodOpacity={0.5}
                stdDeviation="30"
              />
            </filter>

            {/* Background color */}
            <filter
              id="bg-rounded"
              x="-15%"
              width="130%"
              y="-24%"
              height="130%"
            >
              <feFlood floodColor="#fff" />
              <feGaussianBlur stdDeviation="5.5" />
              <feComponentTransfer>
                <feFuncA type="table" tableValues="0 0 0 1" />
              </feComponentTransfer>

              <feComponentTransfer>
                <feFuncA type="table" tableValues="0 1 1 1 1 1 1 1" />
              </feComponentTransfer>
              <feComposite operator="over" in="SourceGraphic" />
            </filter>
          </defs>

          {/* Shapes */}
          <g id="root" transform={`translate(${width / 2},${width / 2})`}>
            {/* Parentelas */}
            {Array.from(arcs)
              .reverse()
              .map(([parentelaIdx, { itemArc, shadowArc, text, data }]) => {
                // Skip partner iteration (will be handled within parentela 1)
                if (parentelaIdx === "partner") {
                  return null;
                }

                return (
                  <g
                    id={`parentela-${parentelaIdx}`}
                    key={`parentela-${parentelaIdx}`}
                  >
                    {/* Partner shadow arc */}
                    {parentelaIdx === "1" && (
                      <>
                        {partnerPie
                          .padAngle(padding)(shadowData)
                          .map((node) => (
                            <path
                              key={`shadow-arc-partner`}
                              id={`shadow-arc-partner`}
                              // @ts-ignore
                              d={arcs.get("partner")?.shadowArc(node)}
                              {...shadowArcProps}
                            />
                          ))}
                      </>
                    )}

                    {/* Shadow arc */}
                    {pie
                      .padAngle(padding / +parentelaIdx)(shadowData)
                      .map((node) => (
                        <path
                          key={`shadow-arc-${parentelaIdx}`}
                          id={`shadow-arc-${parentelaIdx}`}
                          // @ts-ignore
                          d={shadowArc(node)}
                          {...shadowArcProps}
                        />
                      ))}

                    {/* Parentela member arcs */}
                    {pie
                      .padAngle(padding / +parentelaIdx)(data)
                      .map((node, idx) => {
                        const id = `arc-${parentelaIdx}-${idx}`;
                        const textArcId = `text-${id}`;
                        const groupArcId = `group-${id}`;

                        return (
                          <motion.g
                            key={id}
                            id={groupArcId}
                            {...arcGroupProps(node.data)}
                          >
                            {/* Arc */}
                            <motion.path
                              id={id}
                              // @ts-ignore
                              d={itemArc(node)}
                              {...arcProps(node.data)}
                            />

                            {/* Text path */}
                            {!!node.data.inheritor && (
                              <>
                                <path
                                  // @ts-ignore
                                  d={text(node)}
                                  id={textArcId}
                                />
                                <motion.text {...labelProps}>
                                  <textPath
                                    startOffset="25%"
                                    xlinkHref={`#${textArcId}`}
                                    textAnchor="middle"
                                  >
                                    {t(snakeCase(node.data.inheritor), {
                                      context: "short",
                                    })}
                                  </textPath>
                                </motion.text>
                              </>
                            )}
                          </motion.g>
                        );
                      })}

                    {/* Partner arc */}
                    {parentelaIdx === "1" && (
                      <>
                        {partnerPie
                          .padAngle(padding)(partnerData)
                          .map((node, idx) => {
                            const id = `arc-partner-${idx}`;
                            const textArcId = `text-${id}`;
                            const groupArcId = `group-${id}`;

                            return (
                              <motion.g
                                key={id}
                                id={groupArcId}
                                {...arcGroupProps(node.data)}
                              >
                                {/* Arc */}
                                <motion.path
                                  id={id}
                                  // @ts-ignore
                                  d={arcs.get("partner")?.itemArc(node)}
                                  {...arcProps(node.data)}
                                />

                                {/* Text paths */}
                                {!!node.data.inheritor && (
                                  <>
                                    <path
                                      // @ts-ignore
                                      d={arcs.get("partner")?.textUpper(node)}
                                      id={`${textArcId}-upper`}
                                    />
                                    <path
                                      // @ts-ignore
                                      d={arcs.get("partner")?.textMiddle(node)}
                                      id={`${textArcId}-middle`}
                                    />
                                    <path
                                      // @ts-ignore
                                      d={arcs.get("partner")?.textLower(node)}
                                      id={`${textArcId}-lower`}
                                    />
                                    <motion.text {...labelProps}>
                                      <textPath
                                        startOffset="75%"
                                        xlinkHref={`#${textArcId}-upper`}
                                        textAnchor="middle"
                                      >
                                        {t("partner", {
                                          context: "variant1",
                                        })}
                                      </textPath>
                                    </motion.text>
                                    <motion.text {...labelProps}>
                                      <textPath
                                        startOffset="75%"
                                        xlinkHref={`#${textArcId}-middle`}
                                        textAnchor="middle"
                                      >
                                        {t("or")}
                                      </textPath>
                                    </motion.text>
                                    <motion.text {...labelProps}>
                                      <textPath
                                        startOffset="75%"
                                        xlinkHref={`#${textArcId}-lower`}
                                        textAnchor="middle"
                                      >
                                        {t("partner", {
                                          context: "variant2",
                                        })}
                                      </textPath>
                                    </motion.text>
                                  </>
                                )}
                              </motion.g>
                            );
                          })}
                      </>
                    )}

                    {/* Parentela index label arcs */}
                    <text
                      id={`parentela-label-${parentelaIdx}`}
                      dy={
                        -1 *
                          arcLines.get(
                            `${+parentelaIdx + 1}` as "2" | "3" | "4"
                          )! +
                        3
                      }
                      textAnchor="middle"
                      dominantBaseline="central"
                      {...parentelaLabelStyles}
                    >
                      {t(`parentela-${parentelaIdx}`)}
                    </text>
                  </g>
                );
              })}

            {/* Center circle */}
            <circle
              id="center-circle"
              cx="0"
              cy="0"
              r={innerRadius}
              style={{ filter: "url(#shadow)" }}
              fill="#fff"
            />

            {/* Center text */}
            <text
              id="center-text"
              fontSize={i18n.language === "fr" ? 36 : 44}
              fontWeight={700}
              textAnchor="middle"
              dominantBaseline="central"
              fill={colors.secondary}
            >
              {t("testator")}
            </text>
          </g>
        </Box>
      </nav>
    </Box>
  );
};

export default InheritorsWizardCircle;
