/* eslint-disable import/no-webpack-loader-syntax */
import textStep1 from "!babel-loader!@mdx-js/loader!./mdx/de/text-step1.mdx";
import textStep1_2 from "!babel-loader!@mdx-js/loader!./mdx/de/text-step1-2.mdx";
import textStep2 from "!babel-loader!@mdx-js/loader!./mdx/de/text-step2.mdx";
import textStep2_2 from "!babel-loader!@mdx-js/loader!./mdx/de/text-step2-2.mdx";
import textAmount from "!babel-loader!@mdx-js/loader!./mdx/de/text-amount.mdx";
import textContactPrivacy from "!babel-loader!@mdx-js/loader!./mdx/de/text-contact-privacy.mdx";
import textContactCompleteIntro from "!babel-loader!@mdx-js/loader!./mdx/de/text-contact-complete-intro.mdx";
import textStep1It from "!babel-loader!@mdx-js/loader!./mdx/it/text-step1.mdx";
import textStep1_2It from "!babel-loader!@mdx-js/loader!./mdx/it/text-step1-2.mdx";
import textStep2It from "!babel-loader!@mdx-js/loader!./mdx/it/text-step2.mdx";
import textStep2_2It from "!babel-loader!@mdx-js/loader!./mdx/it/text-step2-2.mdx";
import textAmountIt from "!babel-loader!@mdx-js/loader!./mdx/it/text-amount.mdx";
import textContactPrivacyIt from "!babel-loader!@mdx-js/loader!./mdx/it/text-contact-privacy.mdx";
import textContactCompleteIntroIt from "!babel-loader!@mdx-js/loader!./mdx/it/text-contact-complete-intro.mdx";
import textStep1Fr from "!babel-loader!@mdx-js/loader!./mdx/fr/text-step1.mdx";
import textStep1_2Fr from "!babel-loader!@mdx-js/loader!./mdx/fr/text-step1-2.mdx";
import textStep2Fr from "!babel-loader!@mdx-js/loader!./mdx/fr/text-step2.mdx";
import textStep2_2Fr from "!babel-loader!@mdx-js/loader!./mdx/fr/text-step2-2.mdx";
import textAmountFr from "!babel-loader!@mdx-js/loader!./mdx/fr/text-amount.mdx";
import textContactPrivacyFr from "!babel-loader!@mdx-js/loader!./mdx/fr/text-contact-privacy.mdx";
import textContactCompleteIntroFr from "!babel-loader!@mdx-js/loader!./mdx/fr/text-contact-complete-intro.mdx";

const translations = {
  de: {
    translation: {
      inheritance_calculator: "Erbschaftsrechner",

      testator: "Erblasser*in",
      partner_variant1: "Ehepartner*in",
      partner_variant2: "Eingetragene*r Partner*in",
      or: "oder",
      partner: "$t(partner_variant1) $t(or) $t(partner_variant2)",
      children: "Kinder",
      grand_children: "Nachkommen der Kinder",
      grand_children_short: "Enkel*innen und Urenkel*innen",
      mother: "Mutter",
      father: "Vater",
      siblings: "Schwestern und Brüder",
      nieces: "Nachkommen der Geschwister",
      nieces_short: "Nichten und Neffen",
      aunt_uncle: "Tanten und Onkel",
      grand_parents: "Grossmütter und Grossväter",
      cousins: "Nachkommen der Tanten und Onkel",
      cousins_short: "Cousinen und Cousins",
      solo: "Ich bin alleinstehend/ich habe keine gesetzlichen Erben",

      descendants: "Nachkommen (Kinder, Enkel etc.)",
      close_relatives: "Geschwister, Nichten, Neffen und deren Nachkommen",
      other_relatives: "Sonstige Verwandte",
      canton: "Kanton und Gemeinde",
      disposable_portion: "Freie Quote",

      companion: "Konkubinats- oder Lebenspartner/in",
      companion_children: "Kinder der/des Konkubinats- oder Lebenspartner/in",
      relative_in_law: "Verwandte des überlebenden Partners",
      foster_children: "Pflegekinder",
      friend: "Freundin/Bekannte",
      sps: "Schweizer Paraplegiker-Stiftung",
      other_institution: "andere Institution",

      step_1: "Ihre Eingabe",
      step_2: "Optionale Eingabe",
      step_3: "Resultat",

      next: "Weiter",
      prev: "Zurück",

      disposable_portion_text:
        "Sie haben eine freie Quote von <0></0><1></1> zur Verfügung, mit der Sie <2></2> sowie weitere Dritte begünstigen können.",

      email: "E-Mail-Adresse",
      title: "Anrede",
      first_name: "Vorname",
      last_name: "Nachname",
      birthday: "Geburtstag",
      city: "Wohnort",
      title_male: "Herr",
      title_female: "Frau",

      sign_up: "Anmelden",
      complete_profile: "Profil updaten",
      completed_profile: "Vielen Dank",

      "parentela-1": "1. Parentel",
      "parentela-2": "2. Parentel",
      "parentela-3": "3. Parentel",

      more_info: "Weitere Informationen",

      heading_statutory_portions: "Gesetzliche Erbteile",
      text_statutory_portions:
        "Die Grafik zeigt auf, welchen Anteil die gesetzlichen Erben erhalten, wenn keine letztwillige Verfügung von Todes wegen (kein Testament, kein oder Erbvertrag) getroffen worden ist.",
      heading_disposable_portions: "Pflichtteile und frei verfügbare Quote",
      text_disposable_portions:
        "Die Grafik zeigt auf, welchen Mindestanteil Ihre Erben erhalten (Pflichtteil) und über welchen Anteil Sie frei verfügen können. Wie Sie über diese freie Quote verfügen möchten, muss in einer Verfügung von Todes wegen (Testament oder Erbvertrag) festgehalten werden.",

      heading_step_1: "Gesetzliche Erben bestimmen",
      text_step_1: textStep1,
      text_step_1_2: textStep1_2,

      heading_step_2: "Nahestehende Personen bestimmen",
      text_step_2: textStep2,
      text_step_2_2: textStep2_2,

      heading_amount: "Nachlassvermögen (optional)",
      text_amount: textAmount,

      text_step_3:
        "Die obigen Resultate beruhen auf Ihren Angaben und geben einen Hinweis über Ihre mögliche Erbschaftssituation. Sie sind ohne Gewähr auf Vollständigkeit und Richtigkeit.",

      hints: {
        children:
          "Ausserhalb der Ehe geborene/adoptierte Kinder sind den ehelichen Kindern erbrechtlich gleichgestellt.",
        grand_children: "Enkel und/oder Urenkel",
        nieces: "Nichten, Neffen und deren Nachkommen",
        cousins: "Cousin/en und deren Nachkommen",
        relative_in_law:
          "Schwiegereltern, Schwiegerkinder, Schwägerin oder Schwager",
        solo: "Hinterlassen Sie keine Erben und besteht keine letztwillige Verfügung (Testament oder Erbvertrag), so fällt die Erbschaft an den Kanton und allenfalls an die Gemeinde, die vom Kanton Ihres letzten Wohnsitzes als erbberechtigt erklärt wird.",
      },

      heading_contact: "Brauchen Sie weitere Tipps zur Nachlassplanung?",
      contact_sps_text:
        "Es freut uns, dass Sie sich vorstellen könnten, die Schweizer Paraplegiker-Stiftung in Ihrem Testament zu berücksichtigen. Wir möchten Sie gerne persönlich ansprechen und würden uns freuen, Sie persönlich kennenzulernen.",
      contact_cta_text: "Jetzt Tipps erhalten",
      contact_privacy: textContactPrivacy,
      heading_contact_complete: "Fast geschafft!",
      heading_contact_complete_succeeded:
        "Vielen Dank! Sie hören bald von uns.",
      contact_complete_intro: textContactCompleteIntro,

      print: "Drucken",
    },
  },
  it: {
    translation: {
      inheritance_calculator: "Calcolatore di quote ereditarie",

      testator: "Testatore",
      partner_variant1: "Coniuge",
      partner_variant2: "partner registrato",
      or: "o",
      partner: "$t(partner_variant1) $t(or) $t(partner_variant2)",
      children: "Figli",
      grand_children: "Discendenti dei figli",
      grand_children_short: "Nipoti e pronipoti",
      mother: "Madre",
      father: "Padre",
      siblings: "Fratelli e sorelle",
      nieces: "Discendenti dei/delle fratelli/sorelle",
      nieces_short: "Nipoti (delle zie / degli zii)",
      aunt_uncle: "Zie e zii",
      grand_parents: "Nonne e nonni",
      cousins: "Discendenti delle/degli zie e zii",
      cousins_short: "Discendenti delle/degli zie e zii",
      solo: "Sono nubile/celibe / non ho eredi legittimi",

      descendants: "Discendenti (figli, nipoti ecc.)",
      close_relatives: "Fratelli/sorelle, nipoti e i loro discendenti",
      other_relatives: "Altri parenti",
      canton: "Cantone e comune",
      disposable_portion: "Quota disponibile",

      companion: "Partner concubino o convivente",
      companion_children: "Figli del/della partner concubino o convivente",
      relative_in_law: "Parenti del/della partner superstite",
      foster_children: "Minori affiliati",
      friend: "Amico/-a / conoscente",
      sps: "Fondazione svizzera per paraplegici",
      other_institution: "Altre istituzioni",

      step_1: "Dati personali",
      step_2: "Dati opzionali",
      step_3: "Risultato",

      next: "Avanti",
      prev: "Indietro",

      disposable_portion_text:
        "Avete una quota disponibile del <0></0><1></1> con cui potete favorire i <2></2> o convivente e altri terzi.",

      email: "Indirizzo e-mail",
      title: "Appellativo",
      first_name: "Nome",
      last_name: "Cognome",
      birthday: "Geburtstag",
      city: "Wohnort",
      title_male: "Signor",
      title_female: "Signora",

      sign_up: "Anmelden",
      complete_profile: "Aggiorna profilo",
      completed_profile: "Grazie mille",

      "parentela-1": "1° grado di parentela",
      "parentela-2": "2° grado di parentela",
      "parentela-3": "3° grado di parentela",

      more_info: "Weitere Informationen",

      heading_statutory_portions: "Quota ereditaria legale",
      text_statutory_portions:
        "Il grafico mostra la quota che gli eredi legittimi ricevono se alla morte non è stata redatta alcuna disposizione testamentaria (nessun testamento o contratto successorio).",
      heading_disposable_portions: "Porzioni legittime e quota disponibile",
      text_disposable_portions:
        "Il grafico mostra la quota minima che ricevono i vostri eredi (porzione legittima) e la quota di cui potete disporre liberamente. Il modo in cui desiderate disporre di questa quota disponibile deve essere stabilito in una disposizione testamentaria a causa di morte (testamento o contratto successorio).",

      heading_step_1: "Nominare eredi legittimi",
      text_step_1: textStep1It,
      text_step_1_2: textStep1_2It,

      heading_step_2: "Nominare persone di fiducia",
      text_step_2: textStep2It,
      text_step_2_2: textStep2_2It,

      heading_amount: "Patrimonio ereditario (opzionale)",
      text_amount: textAmountIt,

      text_step_3:
        "I risultati di cui sopra si basano sulle vostre informazioni e forniscono un’indicazione sulla vostra possibile situazione ereditaria. Con riserva di correttezza e completezza",

      hints: {
        children:
          "I figli nati/adottati al di fuori del matrimonio sono equiparati ai figli legittimi dal punto di vista del diritto successorio.",
        grand_children: "Nipoti e/o pronipoti",
        nieces: "Nipoti e i loro discendenti",
        cousins: "Cugine/-i e i loro discendenti",
        relative_in_law: "Suoceri, generi, nuore, cognate o cognati",
        solo: "Se non lasciate eredi e non sussiste una disposizione testamentaria (testamento o contratto successorio), la successione è devoluta al Cantone in cui avete avuto l’ultimo domicilio o eventualmente al Comune designato dal diritto di questo Cantone.",
      },

      heading_contact:
        "Avete bisogno di ulteriori consigli per pianificare la vostra successione?",
      contact_sps_text:
        "Es freut uns, dass Sie sich vorstellen könnten, die Schweizer Paraplegiker-Stiftung in Ihrem Testament zu berücksichtigen. Wir möchten Sie gerne persönlich ansprechen und würden uns freuen, Sie persönlich kennenzulernen.",
      contact_cta_text: "Per saperne di più",
      contact_privacy: textContactPrivacyIt,
      heading_contact_complete: "È quasi fatta!",
      heading_contact_complete_succeeded:
        "Grazie mille! A breve riceverà nostre notizie.",
      contact_complete_intro: textContactCompleteIntroIt,

      print: "Stampare",
    },
  },
  fr: {
    translation: {
      inheritance_calculator: "Calculateur d’héritage",

      testator: "Testatrice/testateur",
      partner_variant1: "Épouse/épouxe",
      partner_variant2: "Partenaire enregistré-e",
      or: "ou",
      partner: "$t(partner_variant1) $t(or) $t(partner_variant2)",
      children: "Enfant/s",
      grand_children: "Descendant-es des enfants",
      grand_children_short: "Descendant-es des enfants",
      mother: "Mère",
      father: "Père",
      siblings: "Sœurs et frères",
      nieces: "Descendant-es des frères et sœurs",
      nieces_short: "Neveux et nièces",
      aunt_uncle: "Tantes et oncles",
      grand_parents: "Grands-parents",
      cousins: "Descendant-es des tantes et oncles",
      cousins_short: "Descendant-es des tantes et oncles",
      solo: "Je suis célibataire/je n’ai pas d’héritières ou d’héritiers",

      descendants: "Descendant-es (enfants, petits-enfants, etc.)",
      close_relatives: "Frères et sœurs, nièces, neveux et leurs descendant-es",
      other_relatives: "Autres membres de la famille",
      canton: "Canton et commune",
      disposable_portion: "Quotité disponible",

      companion: "Concubin-e ou partenaire",
      companion_children:
        "Enfants de la conjointe ou du conjoint ou partenaire",
      relative_in_law: "Proches de la/du partenaire survivant-e",
      foster_children: "Enfants confiés",
      friend: "Ami-e/connaissance",
      sps: "Fondation suisse pour paraplégiques",
      other_institution: "Autre institution",

      step_1: "Saisie des données",
      step_2: "Saisie facultative",
      step_3: "Résultat",

      next: "Suivant",
      prev: "Retour",

      disposable_portion_text:
        "Vous disposez d’une quotité disponible de <0></0><1></1> avec laquelle vous pouvez favoriser <2></2> votre partenaire ou autres tiers.",

      email: "Adresse e-mail",
      title: "Civilité",
      first_name: "Prénom",
      last_name: "Nom",
      birthday: "Geburtstag",
      city: "Wohnort",
      title_male: "Monsieur",
      title_female: "Madame",

      sign_up: "Anmelden",
      complete_profile: "Mettre à jour le profil",
      completed_profile: "Merci beaucoup",

      "parentela-1": "1re parentèle",
      "parentela-2": "2e parentèle",
      "parentela-3": "3e parentèle",

      more_info: "Weitere Informationen",

      heading_statutory_portions: "Parts héréditaires légales",
      text_statutory_portions:
        "Le graphique indique la part que reçoivent les héritiers légaux s’il n’y a pas de disposition pour cause de mort (pas de testament ni de contrat de succession).",
      heading_disposable_portions: "Parts réservataires et quotité disponible",
      text_disposable_portions:
        "Le graphique indique la part minimale que vos héritiers reçoivent (part réservataire) et la part dont vous pouvez disposer librement. Vous devez déterminer dans une disposition pour cause de mort (testament ou contrat de succession) comment vous souhaitez disposer de cette quotité disponible.",

      heading_step_1: "Déterminer les héritières et héritiers",
      text_step_1: textStep1Fr,
      text_step_1_2: textStep1_2Fr,

      heading_step_2: "Déterminer les proches",
      text_step_2: textStep2Fr,
      text_step_2_2: textStep2_2Fr,

      heading_amount: "Patrimoine (en option)",
      text_amount: textAmountFr,

      text_step_3:
        "Les résultats ci-dessus reposent sur vos données et donnent des indices concernant votre situation de succession potentielle. Sous réserve concernant l’exactitude et l’intégralité.",

      hints: {
        children:
          "Les enfants nés/adoptés hors mariage jouissent du même traitement juridique.",
        grand_children: "Petits-enfants et arrière-petits-enfants",
        nieces: "Nièces, neveux et leurs descendant-es",
        cousins: "Cousin-es et leurs descendant-es",
        relative_in_law:
          "Beaux-parents, beaux-enfants, belle-sœur ou beau-frère",
        solo: "Si vous ne laissez pas d’héritières/d’héritiers et qu’il n’y a pas de disposition testamentaire (testament ou contrat de succession), la succession dévolue au canton et, le cas échéant, à la commune désignée comme héritière par le canton de votre dernier domicile.",
      },

      heading_contact:
        "Avez-vous besoin de plus de conseils pour planifier votre succession?",
      contact_sps_text:
        "Es freut uns, dass Sie sich vorstellen könnten, die Schweizer Paraplegiker-Stiftung in Ihrem Testament zu berücksichtigen. Wir möchten Sie gerne persönlich ansprechen und würden uns freuen, Sie persönlich kennenzulernen.",
      contact_cta_text: "En savoir plus",
      contact_privacy: textContactPrivacyFr,
      heading_contact_complete: "Vous y êtes presque!",
      heading_contact_complete_succeeded:
        "Merci beaucoup ! Vous aurez bientôt de nos nouvelles.",
      contact_complete_intro: textContactCompleteIntroFr,

      print: "Imprimer",
    },
  },
};

export default translations;
